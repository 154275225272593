import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Categorydata } from '../data/Categorydata';

const Hdpegranules = () => {
  const { id } = useParams(); // Extract the ID from the URL

  const filteredItems = Categorydata.filter(item => item.categoryname === 'HDPE Granules');
  return (
    <>
      {/* pagte titile section start  */}
      <section className="page_title_mkp_section">
        <div className="page_ti_overly_mkp_div">
          <div className="container">
            <div className="row">
              {/* col start */}
              <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                <div className="page_title_tax_mkp_main_div">
                  <div className="page_title_tax_mkp_div">
                    <h1> HDPE Granules</h1>
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li>HDPE Granules</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* col end */}
            </div>
          </div>
        </div>
      </section>
      {/* pagte titile section end */}

      {/* category page section start */}
      <section className="category_page_section">
        <div className="container">
          
            {filteredItems.map((item) => (
              <Categoryitems
              key={item.id} // Use a unique identifier as the key
              id={item.cateproduct_name.replace(/\s/g, '-')} // Pass the id directly from the item
              image={item.image}
              catdtpro_name={item.catdtpro_name}
              catdtpro_item={item.catdtpro_item}
              catdtpro_mfi={item.catdtpro_mfi}
              catdtpro_density={item.catdtpro_density}
              catdtpro_ash={item.catdtpro_ash}
              catdtpro_color={item.catdtpro_color}
              catdtpro_usages={item.catdtpro_usages}
              catdtpro_grade={item.catdtpro_grade}
              catdtpro_material={item.catdtpro_material}
              catdtpro_pacaging={item.catdtpro_pacaging}
              catdtpro_recycle={item.catdtpro_recycle}
              />
            ))}
          </div>
        
      </section>
      {/* category page section end */}
    </>
  );
};

export default Hdpegranules;

const Categoryitems = ({
  id,
  image,
  catdtpro_name,
  catdtpro_item,
  catdtpro_mfi,
  catdtpro_density,
  catdtpro_ash,
  catdtpro_color,
  catdtpro_usages,
  catdtpro_grade,
  catdtpro_material,
  catdtpro_pacaging,
  catdtpro_recycle
}) => {
  return (
    <>
    <div className="row">
      {/*  col start  */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
      <Link to={`/hdpegranules/${id}`}>
          <div className="category_items_main_div">
            <div className="category_items_img_box">
              <img src={image} alt="" className='img-fluid' />
            </div>
           
          </div>
          </Link>
      </div>
      {/*  col end */}

      {/*  col start  */}
      <div className="col-lg-8 col-md-6 col-sm-12 col-12">
      
      <div className="product_dt_table">
                <h3>Specification</h3>
                <table className='table table-bordered table-striped'>
                  <tbody>

                  {catdtpro_name && (
                      <tr>
                        <th>Name</th>
                        <td>{catdtpro_name}</td>
                      </tr>
                    )}

                {catdtpro_item && (
                    <tr>
                      <th>Item Code</th>
                      <td>{catdtpro_item}</td>
                    </tr>
                     )}



                     {catdtpro_mfi && (
                    <tr>
                      <th>MFI</th>
                       <td>{catdtpro_mfi}</td>
                    </tr>
                     )}

                     {catdtpro_density && (
                    <tr>
                      <th>Density</th>
                       <td>{catdtpro_density}</td>
                    </tr>
                     )}

                     {catdtpro_ash && (
                    <tr>
                      <th>Ash</th>
                       <td>{catdtpro_ash}</td>
                    </tr>
                     )}

                     {catdtpro_color && (
                    <tr>
                      <th>Color</th>
                       <td>{catdtpro_color}</td>
                    </tr>
                     )}

                     {catdtpro_usages && (
                    <tr>
                      <th>Usages</th>
                       <td>{catdtpro_usages}</td>
                    </tr>
                     )}


                     {catdtpro_grade && (
                    <tr>
                      <th>Grade</th>
                       <td>{catdtpro_grade}</td>
                    </tr>
                     )}


                     {catdtpro_material && (
                    <tr>
                      <th>Material</th>
                       <td>{catdtpro_material}</td>
                    </tr>
                     )}

                     {catdtpro_pacaging && (
                    <tr>
                      <th>Packaging</th>
                       <td>{catdtpro_pacaging}</td>
                    </tr>
                     )}

                     {catdtpro_recycle && (
                    <tr>
                      <th>Recyclable</th>
                       <td>{catdtpro_recycle}</td>
                    </tr>
                     )}

                     
                     <tr className='cate_eqbtn'>
                      <td colSpan={2}>
                        <div><a href="/Contact">Enquiry Now</a></div>
                        </td>
                     </tr>

                  

                  </tbody>
                </table>
   
              </div>

              
        
      </div>
      {/*  col end */}
      </div>

    </>
  );
};